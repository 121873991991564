'use client'

import { Message } from '@locmod/intl'
import { type ButtonBaseProps, ButtonBase } from 'components/inputs'
import { type IconName, Icon } from 'components/ui'

import messages from './messages'

export type ButtonProps = ButtonBaseProps & {
  className?: string
  leftIcon?: IconName
  title: Intl.Message | string
  loadingTitle?: Intl.Message | string | null
}

const Button: React.FC<ButtonProps> = (props) => {
  const { title, loadingTitle = messages.processing, leftIcon, loading, ...buttonBaseProps } = props

  const leftIconName = loading ? 'ui/loading' : leftIcon
  const finalTitle = loading ? loadingTitle : title

  return (
    <ButtonBase {...buttonBaseProps} loading={loading}>
      {Boolean(leftIconName) && <Icon className="btn-icon" name={leftIconName!} />}
      {Boolean(finalTitle) && <Message value={finalTitle} html />}
    </ButtonBase>
  )
}

export default Button
